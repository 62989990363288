<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="危险废物名称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
<!--    <div style="margin: 20px 0">
      <el-button type="primary" @click="handleAdd" >添加</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>-->
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="companyEntrustTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="60" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="企业名称"  align="center">
          <template #default="scope">{{scope.row.companyName}}</template>
        </el-table-column>
        <el-table-column label="危险废物名称"  align="center">
          <template #default="scope">{{scope.row.hazardousName}}</template>
        </el-table-column>
        <el-table-column label="废物代码" align="center">
          <template #default="scope">{{scope.row.hazardousCode}}</template>
        </el-table-column>
        <el-table-column label="委托经营单位名称" align="center">
          <template #default="scope">{{scope.row.entrustedCompany}}</template>
        </el-table-column>
        <el-table-column label="许可证编码" align="center">
          <template #default="scope">{{scope.row.licenseCode}}</template>
        </el-table-column>
        <el-table-column label="本年度计划委托利用处置量" align="center">
          <template #default="scope">{{scope.row.thisYearPlanEntrustedNumber}}</template>
        </el-table-column>
        <el-table-column label="上年度实际委托利用处置量" align="center">
          <template #default="scope">{{scope.row.lastYearActualEntrustedNumber}}</template>
        </el-table-column>
        <el-table-column label="单位" align="center">
          <template #default="scope">{{scope.row.unit}}</template>
        </el-table-column>
<!--        <el-table-column label="操作" width="150" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" plain @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>-->
      </el-table>
    </div>

    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="companyEntrustModel"
               :rules="rules"
               ref="companyEntrustFrom"
               label-width="150px"
               size="small">
        <el-form-item label="危险废物名称：" >
          <el-input v-model="companyEntrustModel.hazardousName" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="废物代码：" >
          <el-input v-model="companyEntrustModel.hazardousCode" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="委托经营单位名称：" >
          <el-input v-model="companyEntrustModel.entrustedCompany" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="许可证编码：" >
          <el-input v-model="companyEntrustModel.licenseCode" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="本年度计划委托利用处置量：" >
          <el-input v-model="companyEntrustModel.thisYearPlanEntrustedNumber" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="上年度实际委托利用处置量：" >
          <el-input v-model="companyEntrustModel.lastYearActualEntrustedNumber" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="单位：" >
          <el-input v-model="companyEntrustModel.unit" style="width: 80%" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('companyEntrustFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>


</template>

<script>


import {
  batchDeleteCompanyEntrust,
  deleteCompanyEntrust,
  companyEntrustList,
  updateCompanyEntrust,
  createCompanyEntrust,
} from "@/api/companyEntrust";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  judge: "all",
};
const defaultCompanyEntrust = {
  hazardousName: null,
  hazardousCode: null,
  entrustedCompany: null,
  licenseCode: null,
  thisYearPlanEntrustedNumber: null,
  lastYearActualEntrustedNumber: null,
  unit: null,
};


export default {
  name: 'CompanyEntrust',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
      },
      dialogVisible: false,
      isEditFlag: false,
      companyEntrustModel: Object.assign({}, defaultCompanyEntrust),
    }
  },
  created() {
    this.getTableList()
  },
  methods: {

    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },

    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCompanyEntrust(row.id).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDeleteCompanyEntrust(this.ids).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "批量删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      companyEntrustList(this.listQuery).then(res => {
        if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
        }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.companyEntrustModel = Object.assign({},defaultCompanyEntrust);
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.companyEntrustModel = Object.assign({},row);
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              updateCompanyEntrust(this.companyEntrustModel).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '修改成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createCompanyEntrust(this.companyEntrustModel).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '提交成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },


  }
}
</script>
