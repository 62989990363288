import request from '@/utils/request';

//列表
export function companyEntrustList(data) {
  return request({
    url : '/companyEntrust/companyEntrustList',
    method : 'post',
    data : data
  })
}

//新增
export function createCompanyEntrust(data) {
  return request({
    url : '/companyEntrust/createCompanyEntrust',
    method : 'post',
    data : data
  })
}
//修改
export function updateCompanyEntrust(data) {
  return request({
    url :'/companyEntrust/updateCompanyEntrust',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCompanyEntrust(data) {
  return request({
    url : '/companyEntrust/deleteCompanyEntrust',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCompanyEntrust(data) {
  return request({
    url : '/companyEntrust/batchDeleteCompanyEntrust',
    method : 'post',
    data : data
  })
}
